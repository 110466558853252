import { ExperimentsBag } from '@wix/yoshi-flow-editor';

export const SPECS = {
  AddWidgetToStage: 'specs.tips.AddWidgetToStage',
  WaitForProps: 'specs.tips.WaitForProps',
  CustomTipAsButton: 'specs.tips.CustomTipAsButton',
};

const openedExperimentsForTests: string[] = [SPECS.CustomTipAsButton];

const openedExperimentsForSled: string[] = [SPECS.CustomTipAsButton];

export const getOpenedExperimentsForUnitTests = (): ExperimentsBag => {
  return openedExperimentsForTests.reduce((prv: any, cur: any) => {
    prv[cur] = true;
    return prv;
  }, {});
};

export const getOpenedExperimentsForSledTests = () => {
  return openedExperimentsForSled.reduce((obj, ex) => {
    obj[ex] = true;
    return obj;
  }, {});
};
